import React from "react"
import { Link } from "gatsby"
import { Fade } from "react-awesome-reveal"

const ClientZoneSteps = () => (
  <section className="client-zone-steps">
    <div className="container">
      <div className="row client-zone-steps__item">
        <div className="col-12 col-lg-2 col-xxl-1 offset-xxl-1 item__holder">
          <div className="item__number">
            <p className="big-number">1</p>
          </div>
        </div>
        <div className="col-12 col-lg-3 item__title-holder item__holder">
          <Fade delay={150} direction={"left"} triggerOnce>
            <h2 className="item__title small">
              Umów się <br />
              na spotkanie
            </h2>
          </Fade>
        </div>
        <div className="col-12 col-lg-4 col-xxl-3">
          <Fade delay={750} direction={"left"} triggerOnce>
            <div className="item__content item__holder">
              <img
                data-src={require("../assets/img/klaudia.png")}
                alt=""
                className="content__image lazyload"
              />
              <div className="content__info">
                <p className="content__text content__text--left mb-20">
                  <span className="semi-bold">Klaudia Jodłowska</span>
                  Office Manager
                </p>
                <p className="content__text content__text--left">
                  <a href="tel:+48730637064" className="semi-bold content__link">
                    +48 730 637 064
                  </a>
                  <a href="mailto:klaudia@kw51.pl" className="content__link">
                    klaudia@kw51.pl
                  </a>
                </p>
              </div>
            </div>
          </Fade>
        </div>
        <div className="col-12 col-lg-3 item__holder">
          <Fade delay={1500} direction={"left"} triggerOnce>
            <Link to="/kontakt/" className="btn item__btn">
              Wypełnij formularz
            </Link>
          </Fade>
        </div>
      </div>
      <div className="row client-zone-steps__item">
        <div className="col-12 col-lg-2 col-xxl-1 offset-xxl-1 item__holder">
          <div className="item__number">
            <p className="big-number">2</p>
          </div>
        </div>
        <div className="col-12 col-lg-3 item__title-holder item__holder">
          <Fade delay={150} direction={"left"} triggerOnce>
            <h2 className="item__title small">
              Wybierz <br />
              Mieszkanie
            </h2>
          </Fade>
        </div>
        <div className="col-12 col-lg-4 col-xxl-3 item__content item__holder">
          <Fade delay={750} direction={"left"} triggerOnce>
            <p className="content__text">
              Sprawdź nasze mieszkania <br />i podpisz umowę rezerwacyjną
            </p>
          </Fade>
        </div>
        <div className="col-12 col-lg-3 item__holder">
          <Fade delay={1500} direction={"left"} triggerOnce>
            <Link to="/mieszkania/" className="btn item__btn">
              Wybierz mieszkanie
            </Link>
          </Fade>
        </div>
      </div>
      <div className="row client-zone-steps__item">
        <div className="col-12 col-lg-2 col-xxl-1 offset-xxl-1 item__holder">
          <div className="item__number">
            <p className="big-number">3</p>
          </div>
        </div>
        <div className="col-12 col-lg-3 item__title-holder item__holder item__holder--column">
          <Fade delay={150} direction={"left"} triggerOnce>
            <h2 className="item__title small">Finansowanie</h2>
            <p className="title-holder__text">
              <span className="semi-bold">Potrzebujesz kredytu?</span>
              Skorzystaj teraz z bezpłatnej <br />
              pomocy eksperta finansowego
            </p>
          </Fade>
        </div>
        <div className="col-12 col-lg-4 col-xxl-3">
          <Fade delay={750} direction={"left"} triggerOnce>
            <div className="item__content item__holder">
              <img
                data-src={require("../assets/img/tomasz.png")}
                alt=""
                className="content__image lazyload"
              />
              <div className="content__info">
                <p className="content__text content__text--left content__text--name">
                  <span className="semi-bold">Tomasz Jodłowski</span>
                </p>
                <p className="content__text content__text--left">
                  <a href="tel:+48737497199" className="semi-bold content__link">
                    +48 737 497 199
                  </a>
                  <a
                    href="mailto:tomasz.jodlowski@phinance.pl"
                    className="content__link"
                  >
                    tomasz.jodlowski@phinance.pl
                  </a>
                </p>
              </div>
            </div>
          </Fade>
        </div>
        <div className="col-12 col-lg-3 item__holder">
          <Fade delay={1500} direction={"left"} triggerOnce>
            <Link to="/kontakt/" className="btn item__btn">
              Wypełnij formularz
            </Link>
          </Fade>
        </div>
      </div>
      <div className="row client-zone-steps__item">
        <div className="col-12 col-lg-2 col-xxl-1 offset-xxl-1 item__holder">
          <div className="item__number">
            <p className="big-number">4</p>
          </div>
        </div>
        <div className="col-12 col-lg-3 item__title-holder item__holder">
          <Fade delay={150} direction={"left"} triggerOnce>
            <h2 className="item__title small">
              Umowa
              <br />
              deweloperska
            </h2>
          </Fade>
        </div>
        <div className="col-12 col-lg-4 col-xxl-3 item__content item__holder">
          <Fade delay={750} direction={"left"} triggerOnce>
            <div className="content__info">
              <p className="content__text">Podpisz umowę na zakup mieszkania</p>
            </div>
          </Fade>
        </div>
        <div className="col-3 item__icon item__holder">
          <Fade delay={1500} direction={"left"} triggerOnce>
            <img
              data-src={require("../assets/img/client-zone-icon1.svg")}
              alt=""
              className="icon__item lazyload"
            />
          </Fade>
        </div>
      </div>
      <div className="row client-zone-steps__item">
        <div className="col-12 col-lg-2 col-xxl-1 offset-xxl-1 item__holder">
          <div className="item__number">
            <p className="big-number">5</p>
          </div>
        </div>
        <div className="col-12 col-lg-3 item__title-holder item__holder">
          <Fade delay={150} direction={"left"} triggerOnce>
            <h2 className="item__title small">Wpłaty</h2>
          </Fade>
        </div>
        <div className="col-12 col-lg-4 col-xxl-3 item__content item__holder">
          <Fade delay={750} direction={"left"} triggerOnce>
            <div className="content__info">
              <p className="content__text">
                Dokonaj wpłat na rachunek powierniczy zgodnie z ustalonym
                harmonogramem
              </p>
            </div>
          </Fade>
        </div>
        <div className="col-3 item__icon item__holder">
          <Fade delay={1500} direction={"left"} triggerOnce>
            <img
              data-src={require("../assets/img/client-zone-icon2.svg")}
              alt=""
              className="icon__item lazyload"
            />
          </Fade>
        </div>
      </div>
      <div className="row client-zone-steps__item">
        <div className="col-12 col-lg-2 col-xxl-1 offset-xxl-1 item__holder">
          <div className="item__number">
            <p className="big-number">6</p>
          </div>
        </div>
        <div className="col-12 col-lg-3 item__title-holder item__holder">
          <Fade delay={150} direction={"left"} triggerOnce>
            <h2 className="item__title small">
              Pozwolenie <br />
              na użytkowanie
            </h2>
          </Fade>
        </div>
        <div className="col-12 col-lg-4 col-xxl-3 item__content item__holder">
          <Fade delay={750} direction={"left"} triggerOnce>
            <div className="content__info">
              <p className="content__text">
                Otrzymujesz informację o uzyskaniu pozwolenia na użytkowanie
                budynku, w którym znajduje się Twoje mieszkanie
              </p>
            </div>
          </Fade>
        </div>
        <div className="col-3 item__icon item__holder">
          <Fade delay={1500} direction={"left"} triggerOnce>
            <img
              data-src={require("../assets/img/client-zone-icon3.svg")}
              alt=""
              className="icon__item lazyload"
            />
          </Fade>
        </div>
      </div>
      <div className="row client-zone-steps__item">
        <div className="col-12 col-lg-2 col-xxl-1 offset-xxl-1 item__holder">
          <div className="item__number">
            <p className="big-number">7</p>
          </div>
        </div>
        <div className="col-12 col-lg-3 item__title-holder item__holder">
          <Fade delay={150} direction={"left"} triggerOnce>
            <h2 className="item__title small">Odbiór i klucze</h2>
          </Fade>
        </div>
        <div className="col-12 col-lg-4 col-xxl-3 item__content item__holder">
          <Fade delay={750} direction={"left"} triggerOnce>
            <div className="content__info">
              <p className="content__text">
                Wraz z akceptacją protokołu zdawczo-odbiorczego otrzymujesz klucze
                do mieszkania
              </p>
            </div>
          </Fade>
        </div>
        <div className="col-3 item__icon item__holder">
          <Fade delay={1500} direction={"left"} triggerOnce>
            <img
              data-src={require("../assets/img/client-zone-icon4.svg")}
              alt=""
              className="icon__item lazyload"
            />
          </Fade>
        </div>
      </div>
    </div>
  </section>
)
export default ClientZoneSteps
