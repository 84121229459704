import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import Banner from "../components/banner"
import ClientZoneSteps from "../components/clientZoneSteps"

const ClientZone = () => (
	<Layout>
		<SEO title="Strefa klienta" />
		<Banner title="Strefa klienta" />
        <ClientZoneSteps />
	</Layout>
)

export default ClientZone
